<template>
  <div class="error-page success">
    <div class="error-image">
      <img src="@/assets/img/404.jpg" alt="">
    </div>
    <div class="error-content-wrapper">
      <div class="error-content-logo d-flex justify-content-center align-items-start">
        <span class="state-icon">
          <successIcon/>
        </span>
      </div>
      <div class="error-content">
        <p class="error-title">Pedido efectuado com sucesso</p>
        <router-link to="/" class="generic-btn red error-btn">Voltar á homepage</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import successIcon from "@/components/svg/check.vue";

export default {
name: "success",
  components : {
    successIcon
  },
}
</script>